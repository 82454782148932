<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader/>
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Profile Details</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    <div class="admin-general-section-buttons">
                        <router-link to="/superadmin/dashboard" class="admin-general-section-button">
                            Back To Dashboard
                        </router-link>
                    </div>
                </div>

                <!-- start of edit user section -->
                <div class="col-md-12 col-sm-12 m-auto">
                
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mt-3">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Email</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.user_form.email.$error }">
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <b-form-input
                                        v-model="user_form.email"
                                        id="email"
                                        class=""
                                        :class="{ 'is-invalid': $v.user_form.email.$error }"
                                        placeholder="email@domain.com"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 mt-4 col-md-12 flex-column">
                                <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-2s text-left">Fill in the password if you want to change, leave empty otherwise</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">New Password</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.user_form.password.$error }">
                                    <span><i class="fa-solid fa-lock"></i></span>
                                    <b-form-input
                                        type="password" 
                                        v-model="user_form.password"
                                        id="password" 
                                        class=""
                                        :class="{ 'is-invalid': $v.user_form.password.$error }"
                                        placeholder="s3cretp@ssword"
                                    ></b-form-input>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">New Password Again</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.user_form.password_again.$error }">
                                    <span><i class="fa-solid fa-lock"></i></span>
                                    <b-form-input
                                        type="password" 
                                        v-model="user_form.password_again"
                                        id="password_again" 
                                        class=""
                                        :class="{ 'is-invalid': $v.user_form.password_again.$error }"
                                        placeholder="s3cretp@ssword"
                                    ></b-form-input>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 mt-4 col-md-12 flex-column">
                                <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-2s text-left">Switch on the SMS 2FA if want to recieve verification codes via SMS</h3>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column animate__animated animate__fadeInUp animate__delay-2s">
                                <switches 
                                v-model="user_form.sms_2fa"
                                class="active-switch"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true" 
                                label="Enable"
                                >
                                </switches>
                            </div>

                            <div class="d-flex mb-1 col-md-12 flex-column" v-if="user_form.sms_2fa">
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__fast w-100" :class="{ 'is_invalid': $v.user_form.sms_2fa_phone.$error }">
                                    <span><i class="fa-solid fa-phone"></i></span>
                                    <b-form-input
                                        type="text" 
                                        v-mask="phone_mask" 
                                        v-model="user_form.sms_2fa_phone"
                                        id="sms_2fa_phone" 
                                        class=""
                                        :class="{ 'is-invalid': $v.user_form.sms_2fa_phone.$error }"
                                        placeholder="555-555-5555"
                                    ></b-form-input>
                                </div>
                            </div>

                        </div>

                        <div
                        class="
                        w-form-control
                        animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handleUserReq"
                            class="flex-fill" 
                            :disabled="!is_valid_user"
                            >Update</a
                            >
                        </div>

                    </b-form>

                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import { adminEditUser, adminSaveProfile } from "../../services/AdminService";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import Switches from 'vue-switches';
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, minLength, requiredIf, sameAs } from 'vuelidate/lib/validators';

export default {
    name: "SuperAdminProfile-view",
    components: { SuperAdminHeader, SuperAdminFooter, Switches },
    data() {
        return {
            user_data: null,
            user: [],
            is_valid_user: false,
            is_active: null,
            phone_mask: '###-###-####',
            date_mask: '##/##/####',
            clients:[],
            roles: [],
            user_form: {
                edit_id:'',
                email:'',
                password: '',
                password_again: '',
                sms_2fa: 0,
                sms_2fa_phone: ''
            }
        };
    },

    validations: {
        user_form : {
            email: {required},
            password: {required: false, minLength: minLength(8)},
            password_again: {sameAsPassword: sameAs('password')},
            sms_2fa_phone: {
                required: requiredIf(function (params) {
                    params;
                    if(!this.user_form.sms_2fa) {
                        return false;
                    }
                    return true;  
                })
            },
        }
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    methods: {
        getUserDetails() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                user_id: user_data.user_id
            };
            adminEditUser(params).then(response => {
                if(response.status) {
                    this.is_valid_user = true;
                    this.user = response.data;
                    this.is_active = this.user.user.is_active;
                    // fill in the edit form
                    let user_details = response.data.extra;
                    let self = this;
                    Object.keys(user_details).forEach(function(key,index) {
                        if(Object.prototype.hasOwnProperty.call(self.user_form, key) && user_details[key] !== "" && user_details[key] !== null) {
                            self.user_form[key] = user_details[key];
                            index;
                        }
                    });
                }
            });
        },
        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/admin/login");
            }
        },
        handleUserReq() {
            // fill in client id if user is not superadmin
            this.$v.user_form.$touch();
            if (this.$v.user_form.$invalid) {
                this.makeToast("error", "Please fill all required fields");
            } 
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.user_form;
                data.sms_2fa = data.sms_2fa ? 1 : 0;
                data.token = user_data.token;
                data.edit_id = this.user.user.id;
                adminSaveProfile(data).then(response => {
                    if(response.status){
                        this.user_form.password = "";
                        this.user_form.password_again = "";
                        this.makeToast("success", response.message);
                        this.getUserDetails();
                    } 
                    else {
                        this.makeToast("error", response.message);
                    }
                }); 
            }
        },
    },

    mounted() {
        this.getUserDetails();
    },
    created() {
        this.setUserData();
    }
};
</script>
<style scoped>
.admin-portal #admin-general-table {
    user-select: text !important;
}
</style>