const axios = require('axios');
import { cloud_api_routes } from '../routes/cloud_api_routes.js';
import { checkIfNotTokenIssue } from "../services/Guard";
let cloudAPI = process.env.VUE_APP_CLOUD_API;

export async function adminValidatePodId(pod_id) {
    const requestOptions = {
        pod_id: pod_id,
    };
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_KIOSK_VALIDATE_POD_ID}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function adminLogin(username, password, type="") {
    const requestOptions = {
        username: username,
        password: password,
        login_type: type,
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_LOGIN}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function adminLogout(params) {
    if(typeof localStorage.pod_sess_data !== 'undefined' && typeof localStorage.user_sess_data !== 'undefined') {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        let params = {
            session_id_key : pod_data.session_id_key,
            session_id : pod_data.session_id_key,
            token: user_data.token,
            reason_text: ""
        };
        return await axios.post(`${cloudAPI}${cloud_api_routes.USER_LOGOUT}`, params)
        .then(response => response.data)
        .catch(error => {
            console.log(error.response);
            return error.response;
        });
    }
    else {
        const requestOptions = params;
        return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_LOGOUT}`, requestOptions)
        .then(response => response.data)
        .catch(error => {
            return error.response;
        });
    }
}

export async function adminGetLabsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_LABS}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetLabDetails(params) {
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_EDIT_LAB}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetClientsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_CLIENTS}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveLab(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_LAB}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveLabSchedule(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_LAB_SCHEDULE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveLabScheduleOverride(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_LAB_SCHEDULE_OVERRIDE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteLabScheduleOverride(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_LAB_SCHEDULE_OVERRIDE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUploadLabAllowedIps(params) {
    const requestOptions = params;
    
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_UPLOAD_LAB_ALLOWEDIPS}`, requestOptions, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteAllowedIp(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_ALLOWEDIP}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetPodsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_PODS}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetPodTypesList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_POD_TYPES}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetNavsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_NAVS}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminMacValidate(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_MAC_VALIDATE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSavePod(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_POD}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetGamesList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_GAMES_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveGameIds(params, type) {
    let url = cloudAPI;
    url += (type == 'add' ? cloud_api_routes.ADMIN_SAVE_ADD_GAMEID : cloud_api_routes.ADMIN_SAVE_DEL_GAMEID);
    return await axios.post(`${url}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetClientDetails(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_CLIENT_DETAILS}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetClientPods(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_CLIENT__LAB_PODS}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveClient(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_CLIENT}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminRunCommand(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_RUN_COMMAND}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveGame(params) {
    const requestOptions = params;
    
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_GAME}`, requestOptions, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminEditPod(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_EDIT_POD}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUpdatePod(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_UPDATE_POD}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeletePod(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_POD}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetPalletsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_RGB_PALLETS}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUpdateRGBPallet(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_UPDATE_RGB_PALLET}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUpdateThemeAndLogo(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_UPDATE_THEME_LOGO}`, requestOptions, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetThemeAndLogo(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_GET_THEME_LOGO}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminEditGame(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_EDIT_GAME}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUpdateGame(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_UPDATE_GAME}`, requestOptions, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteGame(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_GAME}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetDomainsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_GET_DOMAINS}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}
export async function adminDeleteDomain(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_DOMAIN}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}
export async function adminSaveDomain(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_ADD_DOMAIN}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetUsersList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.USERS_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveUSer(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.USERS_SAVE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminEditUser(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.USERS_EDIT}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUserSwitches(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.USERS_SWITCHES}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetRolesList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ROLES_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveUserCompTime(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.USERS_ADD_COMP_TIME}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteCompTime(params) {
    return await axios.post(`${cloudAPI}${cloud_api_routes.USERS_DELETE_COMP_TIME}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetAlertsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ALERTS_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveAlert(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ALERTS_SAVE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetMessagesList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.SYSTEM_MESSAGES_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveMessage(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.SYSTEM_MESSAGES_SAVE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteAlert(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ALERTS_DELETE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetDbBackupsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_DB_BACKUPS_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteDbBackup(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DB_BACKUPS_DELETE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetReportSummary(params) {
    return await axios.post(`${cloudAPI}${cloud_api_routes.REPORT_SUMMARY}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUserPayments(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.USERS_PAYMENTS}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminPlayerPlayingActivities(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.USERS_PLAYING_ACTIVITIES}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetReportStatus(params) {
    return await axios.post(`${cloudAPI}${cloud_api_routes.REPORT_STATUS}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUploadLabHomeImage(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_UPLOAD_LAB_HOMEIMAGE}`, requestOptions, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteHomeImage(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_LAB_HOMEIMAGE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminUpdateClient(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_UPDATE_CLIENT}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetFeedbackList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_FEEDBACK_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetLogsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_LOGS_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveLog(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_LOGS_SAVE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveLabRssFeed(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_LAB_RSS_FEED}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteLabRssFeed(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_LAB_RSS_FEED}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetReservationsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.RESERVATIONS_LIST}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveReservation(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.RESERVATIONS_SAVE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteReservation(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.RESERVATIONS_DELETE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetReservationDetails(params) {
    return await axios.post(`${cloudAPI}${cloud_api_routes.RESERVATIONS_EDIT}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveSSOSetting(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_CLIENT_SSO_SETTING}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveAPIKeys(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_LAB_API_KEYS}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteAPIKeys(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_LAB_API_KEYS}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetPodIdsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_GET_UNIVERSAL_POD_ID}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveUniversalPodId(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_SAVE_UNIVERSAL_POD_ID}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeleteUniversalPodId(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_UNIVERSAL_POD_ID}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetPendingPodsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.ADMIN_GET_PENDING_PODS}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminDeletePendingPod(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_DELETE_PENDING_POD}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetSinglePendingPod(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_GET_SINGLE_PENDING_POD}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveControllerTest(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_CONTROLLER_TEST_SAVE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminGetLogsReport(params) {
    return await axios.post(`${cloudAPI}${cloud_api_routes.REPORT_LOGS}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function adminSaveProfile(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PROFILE_SAVE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}