<template>
    <div class="main-layout-section admin-portal secondary">
    
        <AdminHeader @setPodData="setPodData($event)" />
        <AdminPodDashboard v-if="!is_alerts && !is_orientation && is_real_pod" :pod_id="pod_id_key" @handleLogout="handleLogout" :logo_file="logo_file" :needs_save_seat="needs_save_seat" />

        <template v-if="!is_real_pod || is_alerts || is_orientation">
            <div class="w-logo" :class="[(!is_alerts && !is_orientation && is_real_pod) ? 'mb-2' : 'mb-5']">
                <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
            </div>
        </template>
        
        <OrientationWidget @orientationComplete="orientationComplete($event)" :otype="orientation_type" v-if="is_orientation" />
        <!-- notifications section -->
        <AlertsWidget @alertsComplete="alertsComplete($event)" :alerts="alerts" v-if="is_alerts && !is_orientation" />

        <template v-if="!is_real_pod">
            <div class="main-content" v-if="!is_alerts && !is_orientation && !is_real_pod">
                <h2 class="page-heading">Dashboard</h2>
                <h3 class="page-subheading mb-5">Welcome Admin!</h3>

                <!-- start of buttons section -->
                <div class="dashboard-buttons-container col-md-3">
                    <div class="dashboard-buttons">
                        <!-- <router-link to="/admin/labs/deploy" class="dashboard-button">
                            Deploy New Lab
                        </router-link>
                        <router-link to="/admin/pods/deploy" class="dashboard-button">
                            Deploy New Pod
                        </router-link> -->
                        <router-link to="/admin/labs" class="dashboard-button w-100 flex-fill">
                            Manage Labs
                        </router-link>
                        <!-- <button class="dashboard-button" @click="$bvModal.show('pallets-modal')">
                            Default LED Colors
                        </button> -->
                        <router-link to="/admin/games" class="dashboard-button">
                            <!-- <i class="animate__animated animate__fadeIn animate__fast fa-brands fa-xbox icon-button"></i> -->
                            Games
                        </router-link>
                        <router-link to="/admin/settings" class="dashboard-button">
                            <!-- <i class="animate__animated animate__fadeIn animate__fast fa-solid fa-gear icon-button"></i> -->
                            Settings
                        </router-link>
                        <router-link to="/admin/reservations" class="dashboard-button">
                            Reservations
                        </router-link>
                        <router-link to="/common/alerts" class="dashboard-button">
                            Alerts
                        </router-link>
                        <router-link to="/common/users" class="dashboard-button">
                            Users
                        </router-link>
                        <router-link to="/common/reports/status" class="dashboard-button">
                            View Reports
                        </router-link>
                        <router-link to="/admin/profile" class="dashboard-button w-100 flex-fill">
                            Profile
                        </router-link>
                        <a href="javascript:;" @click="handleLogout()" class="dashboard-button">
                            Logout
                        </a>
                    </div>
                </div>
                <!-- end of buttons section -->

            </div>
        </template>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" />
        </div>
        <!-- end of footer section -->

        <!-- start of pallets modal -->
        <b-modal id="pallets-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
        <template #modal-title>
            Update LED Settings
        </template>
            <div class="d-block text-center theme-color-buttons">
            <button
                v-for="clr in rgb_pallets"
                :key="clr.id"
                type="button"
                class="btn bg mb-3"
                :style="{ background: clr.value, color: '#fff' }"
                :class="[ led_state !== clr.id ? 'theme-opacity' : '' ]"
                @click="selectUnselectLED(clr.id)"
            >
                {{ clr.value.toUpperCase() }}
            </button>
        </div>
        <div class="d-flex" v-if="led_state !== ''">
            <button 
            class="modal-button w-100"
            @click="updateLED('led1')"
            >Change LED1</button>
        </div>
        <div class="d-flex mt-2" v-if="led_state !== ''">
            <button 
            class="modal-button w-100"
            @click="updateLED('led2')"
            >Change LED2</button>
        </div>
        </b-modal>

    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetPalletsList, adminUpdateRGBPallet } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import AlertsWidget from "@/components/AlertsWidget.vue";
import OrientationWidget from "@/components/OrientationWidget.vue";
import AdminPodDashboard from "@/components/AdminPodDashboard.vue";
import { getPodId } from "../../services/FlaskService";

export default {
    name: "AdminDashboard-view",
    components: { AdminHeader, AdminFooter, AlertsWidget, OrientationWidget, AdminPodDashboard },
    data() {
        return {
            rgb_pallets : [],
            led_state: "",
            is_alerts: false,
            alerts: [],
            is_orientation: false,
            orientation_type: '',
            is_real_pod: false,
            pod_id_key: '',
            needs_save_seat: false
        };
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    methods: {
        palletsList() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token
        };
        adminGetPalletsList(params).then(response => {
            this.rgb_pallets = response.data.pallets;
        });
        },

        ledModal() {
        this.$bvModal.show('pallets-modal');
        },

        selectUnselectLED(value) {
        if(this.led_state == value) {
            this.led_state = '';
        }
        else {
            this.led_state = value;
        }
        },

        updateLED(type) {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            client_id: user_data.client_id,
            pallet_id: this.led_state,
            led_type: type,
        };
        adminUpdateRGBPallet(params).then(response => {
            this.$bvModal.hide('pallets-modal');
            if(response.status) {
            this.makeToast(response.message, "success", response.message);
            this.led_state = "";
            }
            else {
            this.makeToast(response.message, "error", response.message);
            }
        })
        },

        makeToast(variant = "default", message) {
            //   this.$toast.open({
            //     message: message,
            //     type: variant,
            //     position: 'top-right',
            //     duration: 3000,
            //     dismissible: true
            //   });
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});

        },

        setPodData(obj) {
            this[obj.key] = obj.value;
        },

        handleLogout() {
            this.$children.forEach(function(item) {
                if(item.$options._componentTag == "AdminHeader") {
                    item.handleLogout();
                }
            });
        },
        alertsComplete(obj) {
            obj;
            setTimeout(() => {
                this.is_alerts = false;
                this.alerts = [];
            }, 2000);
        },
        orientationComplete(obj) {
            obj;
            this.is_orientation = false;
        },
        // check is_paid status of the pod in the lab. 
        checkIsPaidLab(){
            if(typeof localStorage.pod_sess_data !== 'undefined') {
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                if(Object.prototype.hasOwnProperty.call(pod_data, "is_paid") && pod_data.is_paid == 1){
                    this.is_paid_lab = pod_data.is_paid;
                }
            }
        },
        getPodIdFromFlask() {
            getPodId().then((pod) => {
                if (
                    Object.prototype.hasOwnProperty.call(pod, "success") &&
                    pod.success &&
                    Object.prototype.hasOwnProperty.call(pod, "data") &&
                    Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                    pod.data.pod_id.length > 0
                ) {
                    this.is_real_pod = true;
                    this.pod_id_key = pod.data.pod_id;
                }
            });
        },
    },

    mounted() {
        document.body.classList.remove('overflow-hidden');
        this.palletsList();
        this.$store.commit("fetchAdminThemeLogo");
    },
    created () {
        this.checkIsPaidLab();
        this.getPodIdFromFlask();
        let params = this.$route.params;
        if(Object.prototype.hasOwnProperty.call(params, "is_orientation") && params.is_orientation && !this.is_paid_lab) {
            this.orientation_type = 'free';
            this.is_orientation = params.is_orientation;
        }
        if(Object.prototype.hasOwnProperty.call(params, "is_orientation_paid") && params.is_orientation_paid && this.is_paid_lab) {
            this.orientation_type = 'paid';
            this.is_orientation = params.is_orientation_paid;
        }
        if(Object.prototype.hasOwnProperty.call(params, "is_alerts") && params.is_alerts) {
            this.is_alerts = params.is_alerts;
            this.alerts = params.alerts;
        }
        if(Object.prototype.hasOwnProperty.call(params, "admin_websocket") && params.admin_websocket) {
            if(params.admin_websocket.command_type == 'save_seat') {
                this.needs_save_seat = true;
            }
        }
    }
};
</script>